import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'gatsby';

import testimonialWda from '../images/testimonial/testimonial-wda.jpeg';
import testimonial123Fleurs from '../images/testimonial/testimonial-frederic.jpeg';

const data = [
  {
    description:
      "Grâce à Thank-You nous avons nettoyé notre base d'affiliés afin de repartir sur des bases saines. Nous avons ainsi divisé notre budget affiliation par 10 sans avoir perdu une seule vente !",
    link: {
      url: '/ressources/interview-de-frederic-guffroy-co-fondateur-d123fleurs',
      label: 'Découvrez le témoignage complet de Frédéric',
    },
    author: 'Frédéric Guffroy',
    job: "Co-fondateur d'123Fleurs",
    picture: testimonial123Fleurs,
  },
  {
    description: (
      <>
        J'avais de grosses suspicions de fraudes de certains affiliés mais je ne
        disposais pas de moyens efficaces qui me permettaient de l'identifier
      </>
    ),
    link: {
      url: '/ressources/use-case-web-deal-auto',
      label:
        'Plus de 50% du budget affiliation économisé - Découvrez le Use Case complet',
    },
    author: 'David Gacquer',
    job: 'Directeur marketing de Web Deal Auto',
    picture: testimonialWda,
  },
];

export default function Testimonials() {
  return (
    <section className="bg-white overflow-hidden">
      <div className="relative max-w-5xl mx-auto ">
        <Carousel
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          swipeable={false}
        >
          {data.map((slide) => (
            <div
              key={slide.author}
              className="pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-28 relative lg:flex lg:items-center"
            >
              <div className="hidden lg:block lg:flex-shrink-0 h-32 w-32 xl:h-48 xl:w-48">
                <img
                  className="object-contain rounded-full"
                  src={slide.picture}
                  alt=""
                />
              </div>
              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-green-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-left text-xl sm:text-2xl leading-9 font-medium text-gray-900">
                    <p>{slide.description}</p>
                    <Link
                      className="text-base text-green-brand"
                      to={slide.link.url}
                    >
                      {slide.link.label}
                    </Link>
                  </div>
                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">
                        <img
                          className="h-12 w-12 rounded-full"
                          src={slide.picture}
                          alt=""
                        />
                      </div>
                      <div className="ml-4 lg:ml-0 text-left ">
                        <div className="text-base font-medium text-gray-900">
                          {slide.author}
                        </div>
                        <div className="text-base font-medium text-gray-500">
                          {slide.job}
                        </div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}
